frappe.ready(function() {
	function adjustDisplay() {
		var width = window.innerWidth; // 获取窗口宽度
		var mobile_view = document.getElementById('mobile_view')
		var pc_view = document.getElementById('pc_view')
		var chart = document.getElementById('chart')
		if (chart) {

			chart.scrollLeft = chart.scrollWidth;
		}
		if ( mobile_view && pc_view ) {
			if (width < 600) { // 假设600px为阈值
				document.getElementById('mobile_view').style.display = 'block'; // 显示元素A
				document.getElementById('pc_view').style.display = 'none';  // 隐藏元素B
			} else {
				document.getElementById('mobile_view').style.display = 'none';  // 隐藏元素A
				document.getElementById('pc_view').style.display = 'block'; // 显示元素B
			}

			
			
		}
		
	}
	function scrollLeft() {
		var chart = document.getElementById('chart')
		if (chart) {
			chart.scrollLeft = chart.scrollWidth;
		}
	}
	// 初始调用一次
	adjustDisplay()
	window.onload = ()=> {
		setTimeout(() => {
			scrollLeft();
		}, 500);
	}
	

	// 监听窗口大小变化
	window.addEventListener('resize', adjustDisplay);
})